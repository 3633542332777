import React from "react"
import Page from "../../../components/page"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import ScrollDown from "../../../components/scroll-down"
import { themes } from "../../../styles/themes"
import paths from "../../../utils/paths"
import useScreenSize from "../../../utils/useScreenSize"

const dressedToImpressGrid = (labels, isSmallScreen) => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/dressed-to-impress/glam-collection.jpg",
        title: isSmallScreen
          ? undefined
          : labels.dressedToImpressGlamCollectionTitle,
        titleContainerClass:
          "dressed-to-impress-glam-collection-title-container",
        titleColor: "black",
        text: isSmallScreen
          ? undefined
          : labels.dressedToImpressExpressYourPersonalityText,
        textClass: "dressed-to-impress-express-your-personality-text",
        alt: labels.dressedToImpressGlamCollectionAlt,
        imgTitle: `${labels.glamCollectionImgTitle}: ${labels.imTheWildOneImgTitle}, ${labels.chicMystiqueImgTitle}, ${labels.cocoBaroccoImgTitle}, ${labels.hotStylishChairImgTitle}`
      }
    ]
  },
  isSmallScreen
    ? {
        type: "grid",
        orientation: "horizontal",
        margin: true,
        grid: [
          {
            type: "html",
            weight: 100,
            html: (
              <div className="paragraph-div-centered-relatively">
                <p className="dressed-to-impress-express-your-personality-text">
                  {labels.dressedToImpressExpressYourPersonalityText}
                </p>
              </div>
            )
          }
        ]
      }
    : {},
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/dressed-to-impress/007-bar.jpg",
        title: labels.dressedToImpress007BarTitle,
        alt: labels.dressedToImpress007BarAlt,
        imgTitle: labels.double07BarImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src:
          "/images/dressed-to-impress/cocktail-collection-black-mirror-ottoman.jpg",
        title: labels.dressedToImpressCocktailCollectionTitle,
        subtitle: labels.dressedToImpressCocktailCollectionSubtitle,
        titleColor: "black",
        alt: labels.dressedToImpressCocktailCollectionAlt,
        imgTitle: `${labels.cocktailCollectionImgTitle}: ${labels.cocktailBlackMirrorImgTitle}, ${labels.cocktailOttomanImgTitle}`
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/dressed-to-impress/cocktail-ottoman.jpg",
        title: labels.dressedToImpressCocktailOttomanTitle,
        titleColor: "black",
        alt: labels.dressedToImpressCocktailOttomanAlt,
        imgTitle: labels.cocktailOttomanImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 40,
        src: "/images/dressed-to-impress/chic-mystique-chair.jpg",
        aspectRation: "589 / 936",
        title: labels.dressedToImpressChicMystiqueTitle,
        alt: labels.dressedToImpressChicMystiqueAlt,
        imgTitle: labels.chicMystiqueImgTitle
      },
      {
        type: "image",
        weight: 58.5,
        src: "/images/dressed-to-impress/chic-mystique-gif.gif",
        aspectRation: "988 / 936",
        alt: labels.dressedToImpressChicMystiqueBodyAlt,
        imgTitle: labels.chicMystiqueImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/dressed-to-impress/whiskey-side-table-detail.jpg",
        text: labels.dressedToImpressDressedToKillText,
        textClass: "dressed-to-impress-dressed-to-kill-text",
        alt: labels.dressedToImpressWhiskeySideTableBaseAlt,
        imgTitle: labels.whiskeySideTableImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 61,
        src: "/images/dressed-to-impress/whiskey-side-table-walnut-leather.jpg",
        title: labels.dressedToImpressWhiskeySideTableTitle,
        subtitle: labels.dressedToImpressWhiskeySideTableSubtitle,
        alt: labels.dressedToImpressWhiskeySideTableAlt,
        imgTitle: labels.whiskeySideTableImgTitle
      },
      {
        type: "image",
        weight: 37.5,
        src: "/images/dressed-to-impress/hot-stylish-chair.jpg",
        title: labels.dressedToImpressHotStylishChairTitle,
        alt: labels.dressedToImpressHotStylishChairAlt,
        imgTitle: labels.hotStylishChairImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/dressed-to-impress/you-look-sexy-mirror.jpg",
        alt: labels.dressedToImpressYouLookSexyMirrorAlt,
        imgTitle: labels.youLookSexyMirrorImgTitle
      }
    ]
  }
]

export default function DressedToImpress({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      title={labels.dressedToImpress}
      theme={themes(labels).ipsBlack(true)}
      description={labels.dressedToImpressDescription}
      keywords={labels.dressedToImpressKeywords}
      ogUrl={paths(labels).dressedToImpress}
      ogImage="/images/og-images/dressed-to-impress.jpg"
      footerPadding
    >
      <ScrollDown
        bigScreenOnly={true}
        arrowClassName="scroll-down-arrow-black"
      />
      <ResponsiveImageGrid grid={dressedToImpressGrid(labels, isSmallScreen)} />
    </Page>
  )
}
